import React from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';
import '../generic-page.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';

const EndfieldTierPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page endfield'} game="endfield">
      <ul className="breadcrumb">
        <li>
          <Link to="/arknights-endfield/">Arknights: Endfield</Link>
        </li>
        <li className="divider">/</li>
        <li>Tier List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/endfield/categories/category_tier.jpg"
            alt="Tier List"
          />
        </div>
        <div className="page-details">
          <h1>Arknights: Endfield Tier List</h1>
          <h2>
            Prydwen's tier list for Arknights: Endfield that rates all currently
            available characters.
          </h2>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Work in progress" />
      <div className="banner countdown">
        <div className="cta">
          <h4>Our Arknights: Endfield Database is currently being created!</h4>
          <p>
            We're waiting for the next CBT to start working on the database.
          </p>
        </div>
      </div>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default EndfieldTierPage;

export const Head: React.FC = () => (
  <Seo
    title="Tier List | Arknights: Endfield | Prydwen Institute"
    description="Character are obtainable units in Arknights: Endfield - find every character available in the game below!"
    game="endfield"
  />
);
